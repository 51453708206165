var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Fährverbindung  bearbeiten",
        createTitle: "Neue Fährverbindung hinzufügen",
        isLoading: _vm.isLoading,
      },
    },
    [
      _c(
        "table",
        [
          _vm.providerType === "provider"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Buchung:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("direkt"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "agency" } }, [
                          _vm._v("über Agentur"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.providerType === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Buchung:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("No-Name"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "ferry" } }, [
                          _vm._v("bestimmte Fähre"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Agentur:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "agencies" },
                      model: {
                        value: _vm.agency,
                        callback: function ($$v) {
                          _vm.agency = $$v
                        },
                        expression: "agency",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "ferry"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Fährgesellschaft:"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "ferries" },
                      model: {
                        value: _vm.ferry,
                        callback: function ($$v) {
                          _vm.ferry = $$v
                        },
                        expression: "ferry",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "startPort" } },
                [_vm._v("Abfahrtshafen:")]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("async-select", {
                    attrs: {
                      api: "ports",
                      "custom-label": (item) => item.place.name,
                      crudLinkTitle: "Fügen Sie einen neuen Port hinzu",
                    },
                    model: {
                      value: _vm.form.startPort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startPort", $$v)
                      },
                      expression: "form.startPort",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "endPort" } }, [
                _vm._v("Ankunftshafen:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("async-select", {
                    attrs: {
                      api: "ports",
                      "custom-label": (item) => item.place.name,
                      crudLinkTitle: "Fügen Sie einen neuen Port hinzu",
                    },
                    model: {
                      value: _vm.form.endPort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endPort", $$v)
                      },
                      expression: "form.endPort",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("service-day-time-field", {
            attrs: {
              label: "Fährzeiten",
              fields: ["startTimeOfDay", "endTimeOfDay", "checkInTimeOfDay"],
            },
            model: {
              value: _vm.form.serviceDayTimes,
              callback: function ($$v) {
                _vm.$set(_vm.form, "serviceDayTimes", $$v)
              },
              expression: "form.serviceDayTimes",
            },
          }),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "type" } }, [
                _vm._v("Art:"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.form.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "day" } }, [
                      _vm._v("Tagfähre"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "night" } }, [
                      _vm._v("Nachtfähre"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "type" } }, [
                _vm._v("Klasse:"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "level" },
                [
                  _c("multiselect", {
                    staticClass: "level-item",
                    staticStyle: { width: "150px" },
                    attrs: {
                      options: [
                        { key: true, value: "ohne Klasse" },
                        { key: false, value: "Klasse festlegen" },
                      ],
                      "track-by": "key",
                      label: "value",
                      placeholder: "Klasse",
                    },
                    model: {
                      value: _vm.hideClass,
                      callback: function ($$v) {
                        _vm.hideClass = $$v
                      },
                      expression: "hideClass",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.form.hideClass && _vm.options.ferryLabels
                    ? _c("multiselect", {
                        staticClass: "level-item",
                        staticStyle: { width: "100px" },
                        attrs: {
                          "track-by": "key",
                          label: "value",
                          placeholder: "Klasse",
                          options: _vm.objectToKeyValueArray(
                            _vm.options.ferryLabels.categorySelect
                          ),
                        },
                        model: {
                          value: _vm.classCategory,
                          callback: function ($$v) {
                            _vm.classCategory = $$v
                          },
                          expression: "classCategory",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          !_vm.form.id
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Option:")]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { staticClass: "checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.return,
                            expression: "form.return",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: true,
                          checked: Array.isArray(_vm.form.return)
                            ? _vm._i(_vm.form.return, true) > -1
                            : _vm.form.return,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.form.return,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = true,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "return",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "return",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "return", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Rückfahrt autmoatisch hinzufügen")]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }