var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Zugeordnete Zusatzleistung bearbeiten",
        createTitle: "Neue Zusatzleistung hinzufügen",
        isLoading: _vm.isLoading,
      },
    },
    [
      _c(
        "table",
        [
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [_vm._v("Zuordnen:")]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "button",
                {
                  staticClass: "button",
                  class: { "is-primary": !_vm.link },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.link = !_vm.link
                    },
                  },
                },
                [_vm._v("einzelne Zusatzleistung")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button",
                  class: { "is-primary": _vm.link },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.link = !_vm.link
                    },
                  },
                },
                [_vm._v("mehrere Zusatzleistungen einer Kategorie")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.providerType === "provider"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Buchung:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("direkt"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "agency" } }, [
                          _vm._v("über Agentur"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.providerType === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Buchung:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("No-Name (oder LT unbekannt)"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "provider" } }, [
                          _vm._v("bestimmter Leistungsträger"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Agentur:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "agencies" },
                      model: {
                        value: _vm.agency,
                        callback: function ($$v) {
                          _vm.agency = $$v
                        },
                        expression: "agency",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "provider"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Leistungsträger:"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "providers" },
                      model: {
                        value: _vm.provider,
                        callback: function ($$v) {
                          _vm.provider = $$v
                        },
                        expression: "provider",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.link
            ? [
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      {
                        staticClass: "label",
                        attrs: { for: "otherServiceType" },
                      },
                      [_vm._v("Zusatzleistung:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("async-select", {
                          attrs: {
                            api: "other_service_types",
                            params: {
                              _groups: [
                                "area_read",
                                "place_read",
                                "destination_read",
                              ],
                            },
                            "custom-label": _vm.otherServiceTypeLabel,
                            crudLinkTitle:
                              "Füge einen neuen Leistungsträger hinzu",
                          },
                          on: { input: _vm.setType },
                          model: {
                            value: _vm.otherServiceType,
                            callback: function ($$v) {
                              _vm.otherServiceType = $$v
                            },
                            expression: "otherServiceType",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "subtitle" } },
                      [_vm._v("Interner Zusatztitel:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.subtitle,
                            expression: "form.subtitle",
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.subtitle },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "subtitle", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", { staticClass: "is-top" }, [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "description" } },
                      [_vm._v("Interne Beschreibung:")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("tip-tap", {
                          model: {
                            value: _vm.form.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "description", $$v)
                            },
                            expression: "form.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            : [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Kategorie:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _vm.options.otherServiceType.category
                        ? _c("multiselect", {
                            attrs: {
                              "track-by": "key",
                              label: "value",
                              options: _vm.options.otherServiceType.category,
                            },
                            model: {
                              value: _vm.category,
                              callback: function ($$v) {
                                _vm.category = $$v
                              },
                              expression: "category",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
          _vm._v(" "),
          _vm.link
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label", attrs: { for: "" } }, [
                      _vm._v("Leistung gültig für:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("location-select", {
                        attrs: { hasDestination: "" },
                        model: {
                          value: _vm.location,
                          callback: function ($$v) {
                            _vm.location = $$v
                          },
                          expression: "location",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.location.area ||
                _vm.location.place ||
                _vm.location.destination ||
                _vm.typeCategory
                  ? [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c("h2", [_vm._v("Zusatzleistungen auswählen:")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: "2" } },
                          [
                            _c("list-select", {
                              attrs: {
                                api: "other_service_types",
                                params: _vm.listParams,
                              },
                              model: {
                                value: _vm.otherServiceTypes,
                                callback: function ($$v) {
                                  _vm.otherServiceTypes = $$v
                                },
                                expression: "otherServiceTypes",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }