<template>
    <modal-form
        editTitle="Zugeordnete Zusatzleistung bearbeiten"
        createTitle="Neue Zusatzleistung hinzufügen"
        v-bind:isLoading="isLoading"
    >
        <table>
            <tr>
                <th><label class="label">Zuordnen:</label></th>
                <td>
                    <button
                        class="button"
                        v-on:click.prevent="link = !link"
                        v-bind:class="{'is-primary': !link}"
                    >einzelne Zusatzleistung</button>
                    <button
                        class="button"
                        v-on:click.prevent="link = !link"
                        v-bind:class="{'is-primary': link}"
                    >mehrere Zusatzleistungen einer Kategorie</button>
                </td>
            </tr>
            <tr v-if="providerType === 'provider'">
                <th><label class="label">Buchung:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">direkt</option>
                        <option value="agency">über Agentur</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr v-if="providerType === 'agency'">
                <th><label class="label">Buchung:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">No-Name (oder LT unbekannt)</option>
                        <option value="provider">bestimmter Leistungsträger</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="type === 'agency'">
                <th><label class="label">Agentur:</label></th>
                <td>
                    <async-select
                        api="agencies"
                        v-model="agency"
                    />
                </td>
            </tr>

            <tr v-if="type === 'provider'">
                <th><label class="label">Leistungsträger:</label></th>
                <td>
                    <async-select
                        api="providers"
                        v-model="provider"
                    />
                </td>
            </tr>
            <template v-if="!link">
                <tr>
                    <th><label for="otherServiceType" class="label">Zusatzleistung:</label></th>
                    <td>
                        <div class="control">
                            <async-select
                                api="other_service_types"
                                v-model="otherServiceType"
                                :params="{_groups: ['area_read', 'place_read', 'destination_read']}"
                                :custom-label="otherServiceTypeLabel"
                                crudLinkTitle = "Füge einen neuen Leistungsträger hinzu"
                                @input="setType"
                            />
                        </div>
                    </td>
                </tr>

                <tr>
                    <th><label for="subtitle" class="label">Interner Zusatztitel:</label></th>
                    <td>
                        <div class="control">
                            <input type="text" class="input" v-model="form.subtitle" />
                        </div>
                    </td>
                </tr>

                <tr>
                    <th class="is-top"><label for="description" class="label">Interne Beschreibung:</label></th>
                    <td>
                        <div class="control">
                            <tip-tap v-model="form.description" />
                        </div>
                    </td>
                </tr>
            </template>


            <template v-else>
                <tr>
                    <th><label class="label">Kategorie:</label></th>
                    <td>
                        <multiselect
                            v-if="options.otherServiceType.category"
                            v-model="category"
                            track-by="key"
                            label="value"
                            v-bind:options="options.otherServiceType.category"
                        />
                    </td>
                </tr>
            </template>

            <template v-if="link">
                <tr>
                    <th>
                        <label for="" class="label">Leistung gültig für:</label>
                    </th>
                    <td>
                        <location-select v-model="location" hasDestination />
                    </td>
                </tr>

                <template v-if="location.area || location.place || location.destination || typeCategory">
                    <tr>
                        <td colspan="2">
                            <h2>Zusatzleistungen auswählen:</h2>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2">
                            <list-select
                                api="other_service_types"
                                :params="listParams"
                                v-model="otherServiceTypes"
                            />
                        </td>
                    </tr>
                </template>
            </template>
        </table>
    </modal-form>
</template>



<script>

import TabForm from '../../form'
import { Multiselect, AsyncSelect, ToggleSwitch, LocationSelect, ListSelect } from '@components/form';
import {addOtherService, editOtherService, optionsOtherServiceType} from '@api';
import { notifySuccess, notifyError } from '@components/Notification';
import { TipTap } from '@components/form'
import {omit} from "lodash";

export default {
    mixins: [ TabForm ],

    props: {
        providerType: {
            type: String,
            required: true
        }
    },

    data: function () {
        return {
            type: 'direct',
            otherServiceTypes: [],
            link: false,
            typeCategory: null,
            place: null,
            area: null,
            destination: null,
        }
    },

    form: {
        menu: null,
        otherServiceType: null,
        description: '',
        subtitle: '',
        provider: null,
        agency: null,
    },

    computed: {

        category: {
            get: function () {
                return this.options.otherServiceType.category
                    .find(category => category.key === this.typeCategory)
            },
            set: function (category) {
                this.typeCategory = !!category ? category.key : null
            }
        },

        location: {
            get: function () {
                return {
                    area: this.area,
                    place: this.place,
                    destination: this.destination
                }
            },

            set: function ({area, place, destination}) {
                this.area = area;
                this.place = place;
                this.destination = destination;
            }
        },

        listParams: function () {
            return {
                category: this.typeCategory,
                ...!!this.area && {'area.id' : this.area.id },
                ...!!this.place && {'place.id' : this.place.id },
                ...!!this.destination && {'destination.id' : this.destination.id },
            }
        },

        agency: {
            get: function () { return this.form.agency && !!this.form.agency.id ? this.form.agency : null },
            set: function (agency) { this.form.agency = agency }
        },

        otherServiceType: {
            get: function () { return this.form.otherServiceType && !!this.form.otherServiceType.id ? this.form.otherServiceType : null },
            set: function (otherServiceType) { this.form.otherServiceType = otherServiceType }
        },

        provider: {
            get: function () { return this.form.provider && !!this.form.provider.id ? this.form.provider : null },
            set: function (provider) { this.form.provider = provider }
        },
    },

    created: function () {
        optionsOtherServiceType()
            .then(response => {
                this.$store.commit('updateOptions', { otherServiceType: response.data })
            })
    },

    property: 'otherServices',

    watch: {
        'link': function (isLink) {
            if (!isLink) {
                this.typeCategory = null;
            }
        }
    },

    methods: {
        setType: function(value) {
            this.form.menu = value.category === "restaurant" ? {
                type: 'calculation',
                content: '',
                provider: this.providerType === 'provider' ? `/api/providers/${this.$store.state.id}` : (
                    !!this.form.provider ? `/api/providers/${this.form.provider.id}` : null),
                requestTypes: ['other'],
                title: 'Kalkulationspreis',
            } : null
        },

        otherServiceTypeLabel: function (otherServiceType) {
            const location = this.locationName(otherServiceType);
            return `${otherServiceType.name} ${!!location ? `(${location})` : ''}`;
        },

        locationName: function (otherServiceType) {
            const { area, destination, place } = otherServiceType;
            return !!area ? area.name : !!place ? place.name : !!destination ? destination.name : '';
        },

        handleSubmit: function () {

            if (this.link) {
                this.isLoading = true;
                Promise.all(
                    this.otherServiceTypes.map(type =>
                        addOtherService(
                            {
                                provider: this.providerType === 'provider' ? `/api/providers/${this.$store.state.id}` : (
                                !!this.form.provider ? `/api/providers/${this.form.provider.id}` : null),
                                agency: this.providerType === 'agency' ? `/api/agencies/${this.$store.state.id}` : (
                                !!this.form.agency ? `/api/agencies/${this.form.agency.id}` : null),
                                otherServiceType: `/api/other_service_types/${type.id}`
                            }),
                        {_groups: ['provider_other_service:menu', 'menu']}
                    )
                ).then((responses) => {
                    notifySuccess('Die Zuzatzleistungen wurden erfolgreich hinzugefügt!')
                    this.$emit('submit')
                    this.visible = false;
                    this.isLoading = false;
                }, error => {
                    notifyError("Some items were not added! Error happened")
                    this.isLoading = false;
                })
            } else {
                if(this.form.id){
                    editOtherService({
                        id: this.form.id,
                        data: {
                            ...omit(this.form, 'priceGroups'),
                            otherServiceType: `/api/other_service_types/${this.form.otherServiceType.id}`,
                            provider: this.providerType === 'provider' ? `/api/providers/${this.$store.state.id}` : (
                                !!this.form.provider ? `/api/providers/${this.form.provider.id}` : null
                            ),
                            agency: this.providerType === 'agency' ? `/api/agencies/${this.$store.state.id}` : (
                                !!this.form.agency ? `/api/agencies/${this.form.agency.id}` : null
                            )
                        },
                        params: '?_groups[]=provider_other_service:menu&_groups[]=menu'
                    }).then(() => {
                        this.visible = false;
                        notifySuccess('Die Zuzatzleistung wurde erfolgreich bearbeitet!')
                        this.$emit('submit')
                    }, error => {
                        notifyError("Items aren't added! Error happened")
                    })
                } else {
                    addOtherService( {
                        ...this.form,
                        otherServiceType: `/api/other_service_types/${this.form.otherServiceType.id}`,
                        provider: this.providerType === 'provider' ? `/api/providers/${this.$store.state.id}` : (
                            !!this.form.provider ? `/api/providers/${this.form.provider.id}` : null
                        ),
                        agency: this.providerType === 'agency' ? `/api/agencies/${this.$store.state.id}` : (
                            !!this.form.agency ? `/api/agencies/${this.form.agency.id}` : null
                        ),
                    },
                        {
                            _groups: ['provider_other_service:menu', 'menu']
                        }
                    ).then(() => {
                        this.visible = false;
                        notifySuccess('Die Zuzatzleistung wurden erfolgreich hinzugefügt!')
                        this.$emit('submit')
                    }, error => {
                        notifyError("Items aren't added! Error happened")
                    })
                }
            }
        },
    },
    components: {
        Multiselect,
        AsyncSelect,
        LocationSelect,
        ToggleSwitch,
        ListSelect,
        TipTap
    }
}
</script>
