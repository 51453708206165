<template>
    <modal-form
        editTitle="Fährverbindung  bearbeiten"
        createTitle="Neue Fährverbindung hinzufügen"
        v-bind:isLoading="isLoading"
    >
        <table>
            <tr v-if="providerType === 'provider'">
                <th><label class="label">Buchung:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">direkt</option>
                        <option value="agency">über Agentur</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr v-if="providerType === 'agency'">
                <th><label class="label">Buchung:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">No-Name</option>
                        <option value="ferry">bestimmte Fähre</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="type === 'agency'">
                <th><label class="label">Agentur:</label></th>
                <td>
                    <async-select
                        api="agencies"
                        v-model="agency"
                    />
                </td>
            </tr>

            <tr v-if="type === 'ferry'">
                <th><label class="label">Fährgesellschaft:</label></th>
                <td>
                    <async-select
                        api="ferries"
                        v-model="ferry"
                    />
                </td>
            </tr>
            <tr>
                <th><label for="startPort" class="label">Abfahrtshafen:</label></th>
                <td>
                    <div class="control">
                        <async-select
                            api     = "ports"
                            v-model = "form.startPort"
                            v-bind:custom-label = "item => item.place.name"
                            crudLinkTitle       = "Fügen Sie einen neuen Port hinzu"
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <th><label for="endPort" class="label">Ankunftshafen:</label></th>
                <td>
                    <div class="control">
                        <async-select
                            api     = "ports"
                            v-model = "form.endPort"
                            v-bind:custom-label = "item => item.place.name"
                            crudLinkTitle       = "Fügen Sie einen neuen Port hinzu"
                        />
                    </div>
                </td>
            </tr>
            <service-day-time-field label="Fährzeiten" :fields="['startTimeOfDay', 'endTimeOfDay', 'checkInTimeOfDay']" v-model="form.serviceDayTimes" />

            <tr>
                <th><label for="type" class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="form.type">
                        <option value="day">Tagfähre</option>
                        <option value="night">Nachtfähre</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr>
                <th><label for="type" class="label">Klasse:</label></th>
                <td>
                    <div class="level">
                        <multiselect
                            class="level-item"
                            style="width: 150px"
                            v-model="hideClass"
                            :options="[{key: true, value: 'ohne Klasse'}, {key: false, value: 'Klasse festlegen'}]"
                            track-by="key"
                            label="value"
                            placeholder="Klasse"
                        />
                        <multiselect
                            style="width: 100px"
                            v-if="!form.hideClass && (options.ferryLabels)"
                            v-model="classCategory"
                            track-by="key"
                            label="value"
                            placeholder="Klasse"
                            class="level-item"
                            v-bind:options="objectToKeyValueArray(options.ferryLabels.categorySelect)"
                        />
                    </div>

                </td>
            </tr>
            <tr v-if="!form.id">
                <th><label class="label">Option:</label></th>
                <td>
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox" v-bind:value="true" v-model="form.return">
                            <span>Rückfahrt autmoatisch hinzufügen</span>
                        </label>
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>
</template>



<script>
import TabForm from '../../form'
import { Multiselect, InputDate, DateTime as InputTime, AsyncSelect, ToggleSwitch } from '@components/form';
import { addFerryRoute, editFerryRoute } from '@api';
import {objectToKeyValueArray, prepareForBackend} from '@utilities/functions';
import { serviceDayTimeField, serviceDayTimeMixin } from '@utilities/mixins';


export default {
    mixins: [ TabForm, serviceDayTimeMixin ],

    props: {
        providerType: {
            type: String,
            required: true
        }
    },

    data: function () {
        return {
            type: 'direct',
        }
    },

    form: {
        hideClass: true,
        classCategory: 'second',
        startPort: null,
        endPort: null,
        agency: null,
        ferry: null,
        return: '',
        type: '',
        serviceDayTimes: []
    },

    property: 'ferryRoutes',

    components: {
        Multiselect,
        InputTime,
        InputDate,
        AsyncSelect,
        ToggleSwitch,
        serviceDayTimeField
    },

    computed: {
        hideClass: {
            get: function () {
                return !this.form.hideClass ? {key: false, value: 'Klasse festlegen'} : {key: true, value: 'ohne Klasse'};
            },
            set: function (value) {
                if(value.key) {
                    this.form.classCategory = 'second';
                }
                this.form.hideClass = value.key;
            }
        },
        classCategory: {
            get: function () {
                return objectToKeyValueArray(this.options.ferryLabels.categorySelect).find(label => label.key === this.form.classCategory);

            },
            set: function (value) {
                this.form.classCategory = value.key;
            }
        },

        prepareForBackend: function () {
            return prepareForBackend({
                ...this.form
            })
        },

        ferryRoutes: function () {
            return this.$store.state.data.ferryRoutes
        },

        agency: {
            get: function () { return this.form.agency && !!this.form.agency.id ? this.form.agency : null },
            set: function (agency) { this.form.agency = agency }
        },

        ferry: {
            get: function () { return this.form.ferry && !!this.form.ferry.id ? this.form.ferry : null },
            set: function (provider) { this.form.ferry = provider }
        },
    },

    methods: {
        objectToKeyValueArray,

        setType: function(value) {
            this.type = value;
        },

        handleSubmit: function () {
            this.isLoading = true;

            if(this.providerType === 'agency'){
                this.form.agency = `/api/agencies/${this.$store.state.id}`;
            } else if(this.form.agency){
                this.form.agency = this.form.agency.id ? `/api/agencies/${this.form.agency.id}` : this.form.agency;
            }
            if(this.providerType === 'provider'){
                this.form.ferry = `/api/ferries/${this.$store.state.id}`;
            } else if(this.form.ferry){
                this.form.ferry = this.form.ferry.id ? `/api/ferries/${this.form.ferry.id}` : this.form.ferry;
            }

            if (this.editMode) {
                editFerryRoute({id: this.form.id, data: this.prepareForBackend})
                    .then(response => {
                        this.visible = false;
                        this.notifySuccess('Die Fährverbindung wurde erfolgreich bearbeitet!')
                        this.$emit('submit')

                    }, error => {
                        this.notifyError("Item isn't edited! Error happened")
                    }).then(() => {
                        this.isLoading = false;
                    })
            } else {
                Promise.all([
                    addFerryRoute(this.prepareForBackend),
                    ...this.form.return ? [ addFerryRoute({
                        ...this.prepareForBackend,
                        startPort: this.prepareForBackend.endPort,
                        endPort: this.prepareForBackend.startPort
                    }) ] : []
                ]).then(([ferryRoute, returnFerryRoute]) => {
                    this.visible = false;
                        this.notifySuccess('Die Fährverbindung wurde erfolgreich hinzugefügt')
                        this.$emit('submit')
                }, error => {
                    this.notifyError("Ferry route isn't added! Error happened")
                }).then(() => {
                    this.isLoading = false;
                })
            }

        },
    }
}
</script>
