<template>
    <modal-form
        :editTitle="'Fährpreis' + title + 'bearbeiten'"
        :createTitle="'Neuen Fährpreis' + title + 'hinzufügen'">
        <table>
            <template v-if="initialValue">
                <tr>
                    <th><label class="label">Preis:</label></th>
                    <td>
                        <toggle-switch v-model="priceType">
                            <option value="calculation">Kalkulation</option>
                            <option value="provider">Anbieter</option>
                            <option value="agency">Agentur</option>
                        </toggle-switch>
                    </td>
                </tr>
                <tr v-if="priceType === 'agency'">
                    <th><label class="label">Agentur:</label></th>
                    <td>
                        <async-select
                            api="agencies"
                            v-model="agency"
                        />
                    </td>
                </tr>
                <tr v-if="priceType === 'provider' || priceType === 'agency'">
                    <th><label class="label">Fährgesellschaft:</label></th>
                    <td>
                        <async-select
                            api="ferries"
                            v-model="ferry"
                        />
                    </td>
                </tr>
            </template>
            <tr>
                <th><label class="label">Bezeichnung:</label></th>
                <td>
                    <toggle-switch v-model="ownName">
                        <option :value="true">Individuell</option>
                        <option :value="false">Automatisch (nach Datum)</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr v-if="ownName">
                <th><label class="label">Eigene Bezeichnung:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="name" class="input" v-model="form.name">
                    </div>
                </td>
            </tr>
            <tr>
                <th class="is-top">
                    <label class="label">
                        Zeitraum:
                        <i class="material-icons is-pointer" v-on:click="addPeriod" v-if="canAddPeriod">add_circle</i>
                    </label>
                </th>

                <td>
                    <!-- Time periods -->
                    <div class="level-left" v-for="period in timePeriods" v-bind:key="period.key">
                        <div class="flatpickr-combined level-item">
                            <input-date v-model="period.startAt" class="is-small"  />
                            <span>-</span>
                            <input-date v-model="period.endAt" class="is-small"  />
                        </div>
                        <div class="level-item">
                            <days v-model="period.days" picker/>
                        </div>
                        <i
                            v-if="timePeriods.length > 1"
                            class="material-icons is-pointer"
                            v-on:click="deletePeriod(period)"
                        >delete</i>
                    </div>
                </td>
            </tr>

            <service-day-time-field label="Gültig für" :fields="['startTimeOfDay', 'endTimeOfDay', 'checkInTimeOfDay']" v-model="form.serviceDayTimes" :predefined-day-times="predefinedDayTimes" />


            <!-- Preise -->
            <tr>
                <td colspan="2">
                    <h2>Preise</h2>
                </td>
            </tr>

            <tr>
                <th><label class="label">Überprüft:</label></th>
                <td>
                    <import-checked-field v-model="importChecked" />
                </td>
            </tr>

            <tr>
                <th><label class="label">Währung:</label></th>
                <td>
                    <multiselect
                        v-model="currency"
                        style="width: 70px"
                        :options="currencies"
                        :allow-empty="false"
                        placeholder=""
                    />
                </td>
            </tr>

            <template v-if="form.route && form.route.type === 'night'">
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('inside', 2, allCabinTypes)}}:</label></th>
                    <td><input-price v-model="doubleInsidePrice" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
                </tr>
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('inside', 1, allCabinTypes)}}:</label></th>
                    <td><input-price v-model="singleInsidePrice" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
                </tr>
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('outside', 2, allCabinTypes).replace('Doppelkabine - Außenkabinenzuschlag', 'DK - Außenkabinenzuschlag')}}:</label></th>
                    <td><input-price v-model="doubleOutsidePrice" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
                </tr>
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('outside', 1, allCabinTypes).replace('Einzelkabine - Außenkabinenzuschlag', 'EK - Außenkabinenzuschlag')}}:</label></th>
                    <td><input-price v-model="singleOutsidePrice" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
                </tr>
            </template>
            <tr>
                <th><label class="label">Überfahrt pro Person:</label></th>
                <td><input-price v-model="ticketPrice" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
            </tr>
            <tr>
                <th><label class="label">Bus:</label></th>
                <td><input-price v-model="busPrice" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
            </tr>

            <template v-if="taxes">
                <tr>
                    <th><label class="label">Steuern - Bus:</label></th>
                    <td><input-price v-model="busTaxPrice" class="is-medium" placeholder="0,00" :prefix="currency"/></td>
                </tr>
                <tr>
                    <th><label class="label">Steuern pro Peron:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="ticketTaxPrice" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                            <i class="material-icons" v-on:click="taxes = false">delete</i>
                        </div>
                    </td>
                </tr>
            </template>

            <template v-if="threeBedCabins">
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('inside', 3, allCabinTypes)}}:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="tripleInsidePrice" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('outside', 3, allCabinTypes).replace('Dreibettkabine - Außenkabinenzuschag', '3-K - Außenkabinenzuschlag')}}:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="tripleOutsidePrice" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                            <i class="material-icons" v-on:click="threeBedCabins = false">delete</i>
                        </div>
                    </td>
                </tr>
            </template>

            <template v-if="fourBedCabins">
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('inside', 4, allCabinTypes)}}:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="quadrupleInsidePrice" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th><label class="label">{{ getAccommodationLabel('outside', 4, allCabinTypes).replace('Vierbettkabine - Außenkabinenzuschag', '4-K - Außenkabinenzuschlag')}}:</label></th>
                    <td>
                        <div class="level-left">
                            <div class="level-item">
                                <input-price v-model="quadrupleOutsidePrice" class="is-medium" placeholder="0,00" :prefix="currency"/>
                            </div>
                            <i class="material-icons" v-on:click="fourBedCabins = false">delete</i>
                        </div>
                    </td>
                </tr>
            </template>

            <tr v-if="!taxes">
                <th></th>
                <td>
                    <button class="button" @click.prevent="taxes = true">
                        <i class="material-icons">add_circle</i> Steuern
                    </button>
                </td>
            </tr>

            <tr v-if="!threeBedCabins && form.route && form.route.type === 'night'">
                <th>
                </th>
                <td>
                    <button class="button" @click.prevent="threeBedCabins = true">
                        <i class="material-icons">add_circle</i> 3-Bettkabinen
                    </button>
                </td>
            </tr>

            <tr v-if="!fourBedCabins && form.route && form.route.type === 'night'">
                <th>
                </th>
                <td>
                    <button class="button" @click.prevent="fourBedCabins = true">
                        <i class="material-icons">add_circle</i> 4-Bettkabinen
                    </button>
                </td>
            </tr>

            <tr v-if="existingPrices.length > 0">
                <td class="alert is-danger"></td>
                <td>
                    <div class="alert is-danger" >
                        <p>
                            <strong>Preise überschreiben?</strong>
                        </p>
                        <p>
                            Im angegebenen Preise existieren bereits folgende Preise, die beim erneuten Klick auf Speichern überschrieben werden:
                        </p>
                        <ul>
                            <li v-for="price in existingPrices">{{ price.timePeriods.map(item => item.startAt + ' - ' + item.endAt).join(' | ') }} - EK in der DK: {{ price.providerPrices.filter(item => item.cabinType && item.cabinType.cabinIndex === '2_inside').map(item => item.price.formatted).join(' | ') }}</li>
                        </ul>
                    </div>
                </td>
            </tr>

        </table>
    </modal-form>
</template>

<script>
import has from 'lodash/has';
import Days from '@components/Days';
import { InputDate, DateTime, InputPrice, ToggleSwitch, AsyncSelect } from '@components/form';
import {numbersToDays, getAccommodationLabel, dateISOtoView} from '@utilities/functions';
import TabForm from '../../form'
import { importCheckedMixin, importCheckedField, serviceDayTimeField, serviceDayTimeMixin  } from '@utilities/mixins';
import {
    providerPrices,
    getFerryPlaceholder,
    getFerryRoutes,
    addFerryRoute,
    getFerryPriceGroups
} from '@api'
import {notifySuccess} from "@components/Notification";
import moment from "moment";
import Template from "@orders/subpages/TripDetails/General/Template";


export default {
    mixins: [ TabForm, importCheckedMixin, serviceDayTimeMixin ],

    props: {
        providerType: {
            type: String,
            required: true
        }
    },

    components: {
        Template,
        Days,
        InputDate,
        InputPrice,
        DateTime,
        ToggleSwitch,
        importCheckedField,
        serviceDayTimeField,
        AsyncSelect
    },

    form: {
        route: null,
        name: '',
        serviceDayTimes: [],
        providerPrices: [],
        timePeriods: [],
        updatedAt: dateISOtoView((new Date()).toISOString()),
    },

    data: function () {
        return {
            existingPrices: [],
            ownName: false,
            threeBedCabins: false,
            fourBedCabins: false,
            taxes: false,
            currency: this.$store.state.data.mainCurrency ? this.$store.state.data.mainCurrency : 'EUR',
            priceType: 'calculation',
        }
    },

    computed: {
        title: function () {
            if(!this.form || !this.form.route) {
                return '';
            }
            return this.form.route.name ? ' für ' + this.form.route.name + ' ' : ' für ' + this.form.route.routeString + ' ';
        },

        predefinedDayTimes: function () {
            return this.form.route && this.form.route.serviceDayTimes ? this.form.route.serviceDayTimes.map(detail => {
                detail.key = detail.id;
                delete detail.id;
                return detail;
            }) : [];
        },
        currencies: function () {
            return this.options.potentialCurrencies ? this.options.potentialCurrencies : [];
        },

        timePeriods: function () {
            this.afterDataLoad();
            this.setCurrency();
            return this.form.timePeriods ? this.form.timePeriods : []
        },

        dataCheckedAtCheck: function() {
            if(this.form.route){
                this.afterDataLoad();
                return this.form.dataCheckedAt ? moment(this.form.dataCheckedAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') : null;
            }
            return null
        },

        canAddPeriod: function () {
            return this.timePeriods
                .filter(({startAt, endAt}) => !startAt || !endAt)
                .length === 0
        },

        agency: {
            get: function () { return this.form.route && this.form.agency && !!this.form.route.agency.id ? this.form.route.agency : null },
            set: function (agency) { this.form.route.agency = agency }
        },

        ferry: {
            get: function () { return this.form.route && this.form.route.ferry && !!this.form.route.ferry.id ? this.form.route.ferry : null },
            set: function (provider) { this.form.route.ferry = provider }
        },


        // Prices
        prices: function () { return has(this.form, 'providerPrices') ? this.form.providerPrices : [] },

        doubleInside: function () {return findCabinTypePrice(this.prices, {type: 'inside', persons: 2}) },
        doubleInsidePrice: {
            get: function () { return this.doubleInside ? this.doubleInside.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'inside', persons: 2}, price}) }
        },

        singleInside: function () { return findCabinTypePrice(this.prices, {type: 'inside', persons: 1}) },
        singleInsidePrice: {
            get: function () { return this.singleInside ? this.singleInside.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'inside', persons: 1}, price}) }
        },

        tripleInside: function () {return findCabinTypePrice(this.prices, {type: 'inside', persons: 3}) },
        tripleInsidePrice: {
            get: function () { return this.tripleInside ? this.tripleInside.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'inside', persons: 3}, price}) }
        },

        quadrupleInside: function () {return findCabinTypePrice(this.prices, {type: 'inside', persons: 4}) },
        quadrupleInsidePrice: {
            get: function () { return this.quadrupleInside ? this.quadrupleInside.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'inside', persons: 4}, price}) }
        },

        doubleOutside: function () { return findCabinTypePrice(this.prices, {type: 'outside', persons: 2}) },
        doubleOutsidePrice: {
            get: function () { return this.doubleOutside ? this.doubleOutside.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'outside', persons: 2}, price}) }
        },

        singleOutside: function () { return findCabinTypePrice(this.prices, {type: 'outside', persons: 1}) },
        singleOutsidePrice: {
            get: function () { return this.singleOutside ? this.singleOutside.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'outside', persons: 1}, price}) }
        },

        tripleOutside: function () { return findCabinTypePrice(this.prices, {type: 'outside', persons: 3}) },
        tripleOutsidePrice: {
            get: function () { return this.tripleOutside ? this.tripleOutside.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'outside', persons: 3}, price}) }
        },

        quadrupleOutside: function () { return findCabinTypePrice(this.prices, {type: 'outside', persons: 4}) },
        quadrupleOutsidePrice: {
            get: function () { return this.quadrupleOutside ? this.quadrupleOutside.price.amount : null },
            set: function (price) { this.updatePrice({cabinType: {type: 'outside', persons: 4}, price}) }
        },

        ticket: function () { return findPriceTypePrice(this.prices, 'ticket_price') },
        ticketPrice: {
            get: function () { return this.ticket ? this.ticket.price.amount : null },
            set: function (price) { this.updatePrice({priceType: 'ticket_price', price}) }
        },

        ticketTax: function () { return findPriceTypePrice(this.prices, 'ticket_tax') },
        ticketTaxPrice: {
            get: function () { return this.ticketTax ? this.ticketTax.price.amount : null },
            set: function (price) { this.updatePrice({priceType: 'ticket_tax', price}) }
        },

        bus: function () { return findPriceTypePrice(this.prices, 'bus_price') },
        busPrice: {
            get: function () { return this.bus ? this.bus.price.amount : null },
            set: function (price) { this.updatePrice({priceType: 'bus_price', price}) }
        },

        busTax: function () { return findPriceTypePrice(this.prices, 'bus_tax') },
        busTaxPrice: {
            get: function () { return this.busTax ? this.busTax.price.amount : null },
            set: function (price) { this.updatePrice({priceType: 'bus_tax', price}) }
        },

        allCabinTypes: function () {
            return (this.options && this.options.cabinTypes)
                ? this.options.cabinTypes
                : []
        },

    },

    methods: {
        openCallback: function(form) {
            if(form && form.id && !form.name){
                this.ownName = false;
            }
        },

        getAccommodationLabel,

        handleInitialValue: function(initialValue) {

            this.setSettings()
            this.open();

            if(this.initialValue.sellingPrice){
                this.form.name = this.initialValue.sellingPrice.subTitle;

                this.form.timePeriods = this.initialValue.sellingPrice.seasonTimes.map(season => {
                    return {
                        days: [0,1,2,3,4,5,6],
                        key: season.id,
                        startAt: season.startAt,
                        endAt: season.endAt
                    }
                })
            } else {
                this.form.timePeriods = [{
                    days: [0,1,2,3,4,5,6],
                    startAt: null,
                    endAt: null,
                    key: 1
                }]
            }

            if(this.initialValue.price){

                getFerryPlaceholder(initialValue.price.placeholder.id, '?_groups[]=modal_read&_groups[]=order_placeholder_displayed_request_read').then(response => {
                    const {startPort, endPort, type, displayedRequest} = response.data;
                    let route = {
                        startPort: startPort,
                        endPort: endPort,
                        type: type,
                        agency: displayedRequest ? displayedRequest.agency : null,
                        ferry: displayedRequest ? displayedRequest.ferry : null,
                    }

                    if(route.type === 'night'){
                        this.doubleInsidePrice = initialValue.price.insideDoubleCabinBuyPrice.amount;
                        this.singleInsidePrice = initialValue.price.insideSingleCabinPremiumBuyPrice.amount;
                        this.doubleOutsidePrice = initialValue.price.outsideDoubleCabinBuyPrice.amount;
                        this.singleOutsidePrice = initialValue.price.outsideSingleCabinPremiumBuyPrice.amount;
                        this.busPrice = initialValue.price.busBuyPrice.amount;
                    } else {

                        this.busPrice = initialValue.price.busBuyPrice.amount;
                        this.ticketPrice = initialValue.price.dayTicketBuyPrice.amount;
                    }
                    this.setCurrency();

                    this.form.route = route

                })
            }

            if(this.initialValue.conceptProvider) {
                this.form = {...this.form, ...this.initialValue.conceptProvider, route: {...this.initialValue.conceptProvider, ferry: this.initialValue.conceptProvider.provider}}
            }

            if(this.form.route && this.form.route.agency){
                this.priceType = 'agency';
            } else if(this.form.route && this.form.route.ferry){
                this.priceType = 'provider';
            }
            this.ownName = !!this.form.name;
        },


        setCurrency: function() {
            if(this.doubleInsidePrice && this.doubleInside.price.currency){
                this.currency = this.doubleInside.price.currency;
            }
        },

        setSettings: function() {
            this.threeBedCabins = this.tripleInside || this.tripleOutside;
            this.fourBedCabins = this.quadrupleInside || this.quadrupleOutside;
            this.taxes = this.ticketTax || this.busTax;
        },

        addPeriod: function () {
            this.form.timePeriods.push({
                startAt: null,
                endAt: null,
                days: [0,1,2,3,4,5,6],
                key: + new Date
            })
        },

        deletePeriod: function (period) {
            this.form.timePeriods = this.form.timePeriods
                .filter(({key}) => period.key !== key)
        },

        updatePrice: function ({price, cabinType, priceType}) {
            if (cabinType) {
                const priceIndex = this.form.providerPrices
                    .findIndex(price => price.cabinType && cabinType.type === price.cabinType.type && cabinType.persons === price.cabinType.persons);
                if (priceIndex === -1) {
                    this.form.providerPrices.push({cabinType, price: {amount: price}, key: +new Date})
                } else {
                    this.form.providerPrices.splice(priceIndex, 1, {
                        ...this.form.providerPrices[priceIndex],
                        price: {amount: price},
                    })
                }
            } else {
                const priceIndex = this.form.providerPrices.findIndex(price => priceType === price.priceType)
                if (priceIndex === -1) {
                    this.form.providerPrices.push({
                        price: {amount: price},
                        priceType: priceType,
                        key: +new Date,
                        cabinType: null
                    })
                } else {
                    this.form.providerPrices.splice(priceIndex, 1, {
                        ...this.form.providerPrices[priceIndex],
                        price: {amount: price},
                        cabinType: null
                    })
                }
            }
        },

        getIDFromString: function(apiString) {
            if(typeof apiString === 'object'){
                return apiString.id;
            }
            let stringArray = apiString.split('/');
            return stringArray[stringArray.length - 1];
        },

        checkOverlappingPrices: function () {
            let params = {};
            if(this.existingPrices.length > 0){
                this.addPriceGroup();
            } else {
                let periods = [];
                this.form.timePeriods.forEach(period => {
                    periods.push(period, {...period, startAt: null})
                });
                Promise.all(periods.map(period => {
                    params = {
                        'route.id': this.getIDFromString(this.form.route),
                        'timePeriods.startAt[before]': period.endAt,
                        'timePeriods.endAt[after]': period.startAt,
                        'timePeriods.monday': period.monday.toString(),
                        'timePeriods.tuesday': period.tuesday.toString(),
                        'timePeriods.wednesday': period.wednesday.toString(),
                        'timePeriods.thursday': period.thursday.toString(),
                        'timePeriods.friday': period.friday.toString(),
                        'timePeriods.saturday ': period.saturday.toString() ,
                        'timePeriods.sunday': period.sunday.toString(),
                        ...!period.startAt && {'timePeriods.endAt[exists]': 'false'},
                        '_groups': ['provider_price_group:provider_price', 'provider_price', 'provider_price_group:price_time_period', 'price_time_period', 'cabin_type']
                    };
                    return getFerryPriceGroups(params)
                })).then(responses => {
                    this.existingPrices = [].concat(...(responses.map(response => response.data)));
                    if(this.existingPrices.length === 0){
                        this.addPriceGroup();
                    }
                })
            }
        },


        addPriceGroup: function () {
            providerPrices.ferryPriceGroups.post({
                ...this.form,
                ...{route: this.form.route.id ? `/api/ferry_routes/${this.form.route.id}` : this.form.route},
            })
                .then(response => {
                    notifySuccess('Die Preise wurden erfolgreich hinzugefügt!')
                    this.deletePriceGroups(this.existingPrices);
                    this.$emit('submit')
                    this.isLoading = false;
                    this.visible = false;

                }, error => {
                    this.notifyError("Die Preise konnte nicht hinzugefügt werden!")
                    this.isLoading = false;
                })
        },

        deletePriceGroups: function (groups) {
            groups.map(group => providerPrices.ferryPriceGroups.delete(group.id).then(response => {}, error => {
                this.notifyError("Die bestehenden Preise konnten nicht überschrieben werden. Doppelte Preise bitte manuell ändern!")
            }))
        },

        editPriceGroup: function () {
            providerPrices.ferryPriceGroups.put(
                {
                    ...this.form,
                    ...{route: this.form.route.id ? `/api/ferry_routes/${this.form.route.id}` : this.form.route},
                })
                .then(response => {
                    notifySuccess('Die Preise wurden erfolgreich aktualisiert!')
                    this.isLoading = false;
                    this.$emit('submit')
                    this.visible = false;

                }, error => {
                    this.notifyError("Die Preise konnte nicht aktualisiert werden!")
                    this.isLoading = false;
                })
        },

        handleSubmit: function () {

            this.isLoading = true;
            this.form.timePeriods = this.form.timePeriods.map(period => { return {...period, ...numbersToDays(period.days)}})
            this.form.dataCheckedAt = this.dataCheckedAtCheck;
            this.form.name = this.ownName ? this.form.name : '';

                this.form.providerPrices = this.form.providerPrices.map(price => {
                return Object.assign({}, price, {
                    price: {
                        amount: price.price.amount,
                        currency: this.currency
                    }
                })
            });
            if(this.form.id){
                this.editPriceGroup();
            } else if(this.initialValue && this.form.route.startPort) {

                if(this.priceType === 'calculation'){
                    this.form.route.ferry = null;
                    this.form.route.agency = null;
                }
                if(this.priceType === 'provider'){
                    this.form.route.agency = null;
                }

                //try to find route
                getFerryRoutes({
                    'startPort.id': this.form.route.startPort.id,
                    'endPort.id': this.form.route.endPort.id,
                    type: this.form.route.type,
                    ...this.form.route.agency && {'agency.id': this.form.route.agency.id},
                    ...this.form.route.ferry && {'ferry.id': this.form.route.ferry.id},
                    ...!this.form.route.agency && {'agency[exists]': false},
                    ...!this.form.route.ferry && {'ferry[exists]': false},
                }).then(response => {
                   let routeId = response.data.length > 0 ? response.data[0].id : null;
                    if(routeId){
                        this.form.route.id = routeId;
                        this.checkOverlappingPrices();
                    } else {
                        this.form.route.startPort = '/api/ports/' + this.form.route.startPort.id;
                        this.form.route.endPort = '/api/ports/' + this.form.route.endPort.id;
                        this.form.route.agency = this.form.route.agency ? '/api/agencies/' + this.form.route.agency.id : null;
                        this.form.route.ferry = this.form.route.ferry ? '/api/ferries/' + this.form.route.ferry.id : null;
                        addFerryRoute(this.form.route).then(response => {
                            this.form.route = `/api/ferry_routes/${response.data.id}`;
                            this.addPriceGroup();
                        })
                    }
                });
            } else {
                this.addPriceGroup();
            }
        }
    },
}

const findCabinTypePrice = function (prices, {type, persons}) {
    return prices.find(price => {
        if (!!price && has(price, 'cabinType') && price.cabinType) {
            const { cabinType } = price;
            return cabinType.persons === persons && cabinType.type === type
        } else {
            return false
        }
    })
}

const findPriceTypePrice = function (prices, priceType) {
    return prices.find(price => price.priceType === priceType)
}

</script>
